function App() {
    const agentLink = "https://www.tgifbet.app/index.html?referral_code=20F5C3A5&type=promo";
    return (
        <div className="bg-stone-900">
            <div className="lg:mx-auto lg:min-h-screen lg:max-w-[46vh] relative lg:h-auto h-[216vw]">
                <img src="/bg.png" alt="" className="w-screen h-[216vw] lg:w-auto  lg:h-screen absolute block" />
                <a href={agentLink}>
                    <img
                        src="/download.png"
                        alt=""
                        className="absolute w-[113px] lg:w-[30%] top-[1%] right-[2%] lg:right-[4%]"
                    />
                </a>
                <div className="flex absolute space-x-8 lg:space-x-12 top-[14vw] lg:top-[7%] w-[200px] justify-end right-[10vw] lg:right-[10%] h-[40px]">
                    <a href={agentLink}>
                        <img src="/icon-phone.png" alt="" className="absolute w-[40px] h-[40px]" />
                    </a>
                    <a href={agentLink}>
                        <img src="/icon-news.png" alt="" className="absolute w-[40px] h-[40px]" />
                    </a>
                </div>
                <a href={agentLink} className="block">
                    <img src="/login.png" alt="" className="absolute w-full lg:top-[11%] top-[24vw]" />
                </a>
                <div className="absolute grid grid-cols-3 gap-2 bottom-[13%] mx-4">
                    <a href={agentLink}>
                        <img src="/bonus.png" alt="" />
                    </a>
                    <a href={agentLink}>
                        <img src="/play-now.png" alt="" />
                    </a>
                    <a href={agentLink}>
                        <img src="/vip.png" alt="" />
                    </a>
                </div>
            </div>
        </div>
    );
}

export default App;
