import React from "react";
import ReactDOM from "react-dom/client";
import "./index.sass";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga4";

ReactGA.initialize("G-6TQ0LH3J1Y");
// const tagManagerArgs = {
//     gtmId: "GTM-55C7ZRJT",
// };

// TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
